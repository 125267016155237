

import { PhotoIcon, UserCircleIcon } from '@heroicons/react/24/solid'
import {Fragment, useCallback, useState} from "react";
import {classNames, getImageSrc, isValidHttpUrl, uploadImage} from "../utilities";
import LoadingSpinner from "../components/LoadingSpinner";
import {useDispatch, useSelector} from "react-redux";
import {selectIndustries, selectNewsletterTypes} from "../store/globalSlice";
import {Menu, Transition} from "@headlessui/react";
import {ChevronDownIcon} from "@heroicons/react/20/solid";
import {useNavigate} from "react-router-dom";
import {createNewsletter, selectIsCreatingNewsletter, selectNewsletterCreationError} from "../store/newslettersSlice";

const FREQUENCY = [
  "---", "DAILY", "WEEKLY", "BIWEEKLY", "MONTHLY"
]

const fixCapitalization = (str) => {
  let s = str.toLowerCase()
  s = s[0].toUpperCase() + s.slice(1)
  return s
}

export default function CreateNewsletterPage() {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const industries = useSelector(selectIndustries);
  const newsletterTypes = useSelector(selectNewsletterTypes);
  const isCreatingNewsletter = useSelector(selectIsCreatingNewsletter);
  const newsletterCreationError = useSelector(selectNewsletterCreationError);

  const [newsletterTitle, setNewsletterTitle] = useState("");
  const [newsletterDescription, setNewsletterDescription] = useState("");
  const [logoReference, setLogoReference] = useState(null);
  const [isUploadingLogo, setIsUploadingLogo] = useState(false);
  const [coverPhotoReference, setCoverPhotoReference] = useState(null);
  const [isUploadingCoverPhoto, setIsUploadingCoverPhoto] = useState(false);
  const [signupUrl, setSignupUrl] = useState("");
  const [homepageUrl, setHomepageUrl] = useState("");
  const [author, setAuthor] = useState("");
  const [authorUrl, setAuthorUrl] = useState("");
  const [newsletterType, setNewsletterType] = useState(null);
  const [industry, setIndustry] = useState(null);
  const [frequency, setFrequency] = useState(null);

  // Error settings
  const [newsletterTitleError, setNewsletterTitleError] = useState(null);
  const [logoReferenceError, setLogoReferenceError] = useState(null);
  const [signupUrlError, setSignupUrlError] = useState(null);
  const [homepageUrlError, setHomepageUrlError] = useState(null);
  const [authorUrlError, setAuthorUrlError] = useState(null);
  const [industryError, setIndustryError] = useState(null);
  const [submitError, setSubmitError] = useState(null);

  const validate = useCallback(() => {
    let error = false;
    if (newsletterTitle.trim().length === 0) {
      setNewsletterTitleError("Newsletter title is required");
      error = true;
    }
    if (logoReference == null) {
      setLogoReferenceError("Logo is required");
      error = true;
    }
    if (!isValidHttpUrl(signupUrl)) {
      setSignupUrlError("Signup URL is required (make sure to include https:// or http://)");
      error = true;
    }
    if (homepageUrl && !isValidHttpUrl(homepageUrl)) {
      setHomepageUrlError("Homepage URL is invalid (make sure to include https:// or http://)");
      error = true;
    }
    if (authorUrl && !isValidHttpUrl(authorUrl)) {
      setAuthorUrlError("Author URL is invalid (make sure to include https:// or http://)");
      error = true;
    }
    if (!industry) {
      setIndustryError("Industry is required");
      error = true;
    }
    return error
  }, [newsletterTitle, logoReference, signupUrl, homepageUrl, authorUrl, industry]);

  const clearErrors = useCallback(() => {
    setNewsletterTitleError(null);
    setLogoReferenceError(null);
    setSignupUrlError(null);
    setHomepageUrlError(null);
    setAuthorUrlError(null);
    setIndustryError(null);
    setSubmitError(null);
  }, [])

  const submitNewsletter = useCallback(() => {
    clearErrors();
    const hasErrors = validate();
    if (hasErrors) {
      setSubmitError("There were errors with the data above - please check the information and try again.");
      return;
    }
    // If all good, dispatch an update
    dispatch(createNewsletter({
      name: newsletterTitle,
      authorName: author,
      signupLink: signupUrl,
      logoReference: logoReference,
      newsletterTypeId: newsletterType?.id || null,
      industryId: industry.id,
      description: newsletterDescription,
      authorLink: authorUrl,
      homepageLink: homepageUrl,
      coverPageReference: coverPhotoReference,
      postFrequency: frequency
    })).then((result) => {
      navigate("/newsletter/" + result.payload.data.slug);
    })
  }, [validate, clearErrors, newsletterTitle, author, signupUrl, logoReference, newsletterType, industry, newsletterDescription, authorUrl, homepageUrl, coverPhotoReference, frequency, dispatch])

  const uploadLogo = useCallback(async (event) => {
    if (event.target.files && event.target.files[0]) {
      setIsUploadingLogo(true);
      const key = await uploadImage(event.target.files[0]);
      setLogoReference(key);
      setIsUploadingLogo(false);
    }
  }, [])

  const uploadCoverPhoto = useCallback(async (event) => {
    if (event.target.files && event.target.files[0]) {
      setIsUploadingCoverPhoto(true);
      const key = await uploadImage(event.target.files[0]);
      setCoverPhotoReference(key);
      setIsUploadingCoverPhoto(false);
    }
  }, [])
  return (
    <div className="max-w-[700px] mx-auto my-16 p-8">
      <div className="space-y-12">
        <div className="border-b border-gray-900/10 pb-12">
          <h2 className="text-base font-semibold leading-7 text-gray-900 text-lg">Add a Newsletter</h2>

          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-6">
              <div className="sm:col-span-6">
                <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900">
                  Newsletter Title <span className="text-gray-500 text-sm">(required)</span>
                </label>
                {newsletterTitleError && <p className="text-sm text-red-500">{newsletterTitleError}</p>}
                <div className="mt-2">
                  <input
                    id="title"
                    name="title"
                    type="text"
                    value={newsletterTitle}
                    onChange={(event) => setNewsletterTitle(event.target.value)}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>

            <div className="col-span-full">
              <label htmlFor="description" className="block text-sm font-medium leading-6 text-gray-900">
                Description <span className="text-gray-500 text-sm">(recommended, but optional)</span>
              </label>
              <div className="mt-2">
                <textarea
                  placeholder="Write a few sentences about the newsletter."
                  id="description"
                  name="description"
                  rows={3}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  value={newsletterDescription}
                  onChange={(event) => setNewsletterDescription(event.target.value)}
                />
              </div>
            </div>

            <div className="col-span-full">
              <label htmlFor="photo" className="block text-sm font-medium leading-6 text-gray-900">
                Newsletter Logo <span className="text-gray-500 text-sm">(required)</span>
              </label>
              {logoReferenceError && <p className="text-sm text-red-500">{logoReferenceError}</p>}
              <div className="mt-2 flex items-center gap-x-3">
                {isUploadingLogo && <LoadingSpinner />}
                {!logoReference && !isUploadingLogo && (
                  <UserCircleIcon className="h-12 w-12 text-gray-300" aria-hidden="true"/>
                )}
                {logoReference && !isUploadingLogo && (
                    <img src={getImageSrc(logoReference)} className="h-12 w-12 rounded-md object-cover" alt="" />
                  )
                }
                <label
                  htmlFor="file-upload"
                  className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                >
                  <span>Upload a file</span>
                  <input id="file-upload" name="file-upload" accept="image/png, image/jpeg" type="file" className="sr-only" onChange={uploadLogo} disabled={isUploadingLogo} />
                </label>
              </div>
            </div>

            {/*<div className="col-span-full">*/}
            {/*  <label htmlFor="file-upload-cover" className="block text-sm font-medium leading-6 text-gray-900">*/}
            {/*    {isUploadingCoverPhoto && <LoadingSpinner size={4} />}*/}
            {/*    Cover Photo <span className="text-gray-500 text-sm">(recommended, but optional)</span>*/}
            {/*    {coverPhotoReference && (*/}
            {/*      <label*/}
            {/*        htmlFor="file-upload-cover"*/}
            {/*        className="ml-4 relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"*/}
            {/*      >*/}
            {/*        <span>Change photo</span>*/}
            {/*        <input id="file-upload-cover" name="file-upload-cover" type="file" className="sr-only" onChange={uploadCoverPhoto} disabled={isUploadingCoverPhoto} />*/}
            {/*      </label>*/}
            {/*    )}*/}
            {/*  </label>*/}
            {/*  {!coverPhotoReference && (*/}
            {/*    <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">*/}
            {/*      <div className="text-center">*/}
            {/*        {isUploadingCoverPhoto && <LoadingSpinner />}*/}
            {/*        {!isUploadingCoverPhoto && (*/}
            {/*          <PhotoIcon className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true"/>*/}
            {/*        )}*/}
            {/*        <div className="mt-4 flex text-sm leading-6 text-gray-600">*/}
            {/*          <label*/}
            {/*            htmlFor="file-upload-cover"*/}
            {/*            className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"*/}
            {/*          >*/}
            {/*            <span>Upload a file</span>*/}
            {/*            <input id="file-upload-cover" name="file-upload-cover" type="file" className="sr-only" onChange={uploadCoverPhoto} disabled={isUploadingCoverPhoto} />*/}
            {/*          </label>*/}
            {/*          <p className="pl-1">or drag and drop</p>*/}
            {/*        </div>*/}
            {/*        <p className="text-xs leading-5 text-gray-600">PNG or JPG up to 10MB</p>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  )}*/}
            {/*  {coverPhotoReference && (*/}
            {/*    <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25">*/}
            {/*      <img src={getImageSrc(coverPhotoReference)} className="h-48 w-full rounded-md object-cover" alt="" />*/}
            {/*    </div>*/}
            {/*  )}*/}
            {/*</div>*/}
          </div>

          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

            <div className="sm:col-span-6">
              <label htmlFor="signupUrl" className="block text-sm font-medium leading-6 text-gray-900">
                Newsletter Signup / Subscribe URL <span className="text-gray-500 text-sm">(required)</span>
              </label>
              {signupUrlError && <p className="text-sm text-red-500">{signupUrlError}</p>}
              <div className="mt-2">
                <input
                  placeholder="The full URL to your newsletter signup page."
                  id="signupUrl"
                  name="signupUrl"
                  type="url"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  value={signupUrl}
                  onChange={(event) => setSignupUrl(event.target.value)}
                />
              </div>
            </div>

            <div className="sm:col-span-6">
              <label htmlFor="homepageUrl" className="block text-sm font-medium leading-6 text-gray-900">
                Newsletter Homepage URL <span className="text-gray-500 text-sm">(optional)</span>
              </label>
              {homepageUrlError && <p className="text-sm text-red-500">{homepageUrlError}</p>}
              <div className="mt-2">
                <input
                  placeholder="The full URL to your newsletter homepage."
                  id="homepageUrl"
                  name="homepageUrl"
                  type="url"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  value={homepageUrl}
                  onChange={(event) => setHomepageUrl(event.target.value)}
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="author" className="block text-sm font-medium leading-6 text-gray-900">
                Author <span className="text-gray-500 text-sm">(optional)</span>
              </label>
              <div className="mt-2">
                <input
                  placeholder="e.g. My Company, Inc."
                  type="text"
                  name="author"
                  id="author"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  value={author}
                  onChange={(event) => setAuthor(event.target.value)}
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="authorUrl" className="block text-sm font-medium leading-6 text-gray-900">
                Author Homepage <span className="text-gray-500 text-sm">(optional)</span>
              </label>
              {authorUrlError && <p className="text-sm text-red-500">{authorUrlError}</p>}
              <div className="mt-2">
                <input
                  placeholder="e.g. mycompany.com"
                  type="url"
                  name="authorUrl"
                  id="authorUrl"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  value={authorUrl}
                  onChange={(event) => setAuthorUrl(event.target.value)}
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="industry" className="block text-sm font-medium leading-6 text-gray-900">
                Industry / Topic <span className="text-gray-500 text-sm">(required)</span>
              </label>
              {industryError && <p className="text-sm text-red-500">{industryError}</p>}
              <div className="mt-2">
                <Menu as="div" className="relative inline-block text-left w-full">
                  <div>
                    <Menu.Button className="inline-flex w-full justify-left gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                      <div className="flex flex-row">
                        {industry && (
                          <div className="group flex items-center">
                            {industry.name}
                          </div>
                        )}
                        {!industry && (
                          <span className="flex-1">Select Industry</span>
                        )}
                        <ChevronDownIcon className="flex-none h-5 w-5 text-gray-400" aria-hidden="true" />
                      </div>
                    </Menu.Button>
                  </div>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute w-full right-0 z-10 mt-2 origin-top-left divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        {industries && industries.map((ind) => (
                          <Menu.Item onClick={() => setIndustry(ind)}>
                            {({ active }) => (
                              <div
                                className={classNames(
                                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                  'group flex items-center px-4 py-2 text-sm cursor-pointer'
                                )}
                              >
                                {ind.name}
                              </div>
                            )}
                          </Menu.Item>
                        ))}
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="newsletter-type" className="block text-sm font-medium leading-6 text-gray-900">
                Newsletter Platform <span className="text-gray-500 text-sm">(optional)</span>
              </label>
              <div className="mt-2">
                <Menu as="div" className="relative inline-block text-left w-full">
                  <div>
                    <Menu.Button className="inline-flex w-full justify-left gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                      <div className="flex flex-row">
                        {newsletterType && (
                          <div className="group flex items-center">
                            {newsletterType.logoReference && (
                              <img src={getImageSrc(newsletterType.logoReference)} alt={newsletterType.name} className="w-5 h-5 mr-2" />
                            )}
                            {newsletterType.name}
                          </div>
                        )}
                        {!newsletterType && (
                          <span className="flex-1">Select Newsletter</span>
                        )}
                        <ChevronDownIcon className="flex-none h-5 w-5 text-gray-400" aria-hidden="true" />
                      </div>
                    </Menu.Button>
                  </div>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute w-full right-0 z-10 mt-2 origin-top-left divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        {newsletterTypes && newsletterTypes.map((nt) => (
                          <Menu.Item onClick={() => setNewsletterType(nt)}>
                            {({ active }) => (
                              <div
                                className={classNames(
                                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                  'group flex items-center px-4 py-2 text-sm cursor-pointer'
                                )}
                              >
                                {nt.logoReference && (
                                  <img src={getImageSrc(nt.logoReference)} alt={nt.name} className="w-5 h-5 mr-2" />
                                )}
                                {nt.name}
                              </div>
                            )}
                          </Menu.Item>
                        ))}
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="frequency" className="block text-sm font-medium leading-6 text-gray-900">
                Post Frequency <span className="text-gray-500 text-sm">(optional)</span>
              </label>
              <div className="mt-2">
                <Menu as="div" className="relative inline-block text-left w-full">
                  <div>
                    <Menu.Button className="inline-flex w-full justify-left gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                      <div className="flex flex-row">
                        {frequency && (
                          <div className="group flex items-center">
                            {fixCapitalization(frequency)}
                          </div>
                        )}
                        {!frequency && (
                          <span className="flex-1">Select Frequency</span>
                        )}
                        <ChevronDownIcon className="flex-none h-5 w-5 text-gray-400" aria-hidden="true" />
                      </div>
                    </Menu.Button>
                  </div>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute w-full right-0 z-10 mt-2 origin-top-left divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        {FREQUENCY.map((freq) => (
                          <Menu.Item onClick={() => setFrequency(freq === "---" ? null : freq)}>
                            {({ active }) => (
                              <div
                                className={classNames(
                                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                  'group flex items-center px-4 py-2 text-sm cursor-pointer'
                                )}
                              >
                                {fixCapitalization(freq)}
                              </div>
                            )}
                          </Menu.Item>
                        ))}
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div className="mt-6 flex items-center justify-end gap-x-6">
        {(submitError || newsletterCreationError) && <p className="text-sm text-red-500">{submitError || newsletterCreationError}</p>}
        <button type="button" className="text-sm font-semibold leading-6 text-gray-900" onClick={() => navigate("/")}>
          Cancel
        </button>
        <button
          disabled={isCreatingNewsletter}
          onClick={submitNewsletter}
          className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Save
        </button>
      </div>
    </div>
  )
}