import { useDispatch, useSelector } from "react-redux";
import {
  clearDetailedPageState,
  clearNewsletters,
  getNewsletters,
  getSponsoredNewsletters,
  getMyNewsletters,
  resetPageNumber,
  selectMyNewsletters,
  selectIsLoadingNewsletters,
  selectSponsoredNewsletters,
  selectSponsoredNewslettersIsLoading,
} from "../store/newslettersSlice";
import { useCallback, useEffect, useState } from "react";
import NewsletterCard from "../components/NewsletterCard";
import { DateTime } from "luxon";
import { ChevronLeftIcon } from "@heroicons/react/20/solid";
import LoadingSpinner from "../components/LoadingSpinner";
import SponsorNewsletterCard from "../components/SponsorNewsletterCard";
import { LegalLinks } from "../components/LegalLinks";
import client from "../client";
import { useNavigate } from "react-router-dom";

const ONE_DAY = 86400000;

const SORT_OPTIONS = [
  {
    label: "Trending Today",
    titleDisplay: "Today's Top Trending",
    sortMode: "REVIEWS",
    startDate: DateTime.now().minus(ONE_DAY),
  },
  {
    label: "Trending This Week",
    titleDisplay: "This Week's Top Trending",
    sortMode: "REVIEWS",
    startDate: DateTime.now().minus(ONE_DAY * 7),
  },
  { label: "Top Rated", titleDisplay: "Highest Rated", sortMode: "RATING" },
  {
    label: "Recently Added",
    titleDisplay: "Recently Added",
    sortMode: "CREATION",
  },
];

export default function MyNewslettersPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [newsletters, setNewsletters] = useState();
  const myNewsletters = useSelector(selectMyNewsletters);
  
  const isLoadingNewsletters = useSelector(selectIsLoadingNewsletters);
  const sponsoredNewsletters = useSelector(selectSponsoredNewsletters);
  const sponsoredNewslettersIsLoading = useSelector(
    selectSponsoredNewslettersIsLoading
  );

  const [sortMode, setSortMode] = useState(SORT_OPTIONS[0]);
  const [filterIndustry, setFilterIndustry] = useState(null);

  useEffect(() => {
    dispatch(clearDetailedPageState());
    dispatch(getMyNewsletters());
    dispatch(getSponsoredNewsletters());
  }, []);

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data.status === 'success') {
        dispatch(getMyNewsletters());
      }
    };
    dispatch(resetPageNumber());
    dispatch(clearNewsletters());
    getNewslettersFunction();

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [dispatch]);

  useEffect(() => {
    setNewsletters(myNewsletters);
  }, [myNewsletters, dispatch]);
  
  const getMyNewsletter = useCallback(() => {
    client.handleGoogleAuth()
  }, []);
  
  const getNewslettersFunction = useCallback(() => {
    const params = {
      sortMode: sortMode.sortMode,
    };
    if (filterIndustry) params.industryId = filterIndustry.id;
    if (sortMode.startDate) {
      params.startDate = sortMode.startDate.toISO();
    }
    dispatch(getNewsletters(params));
  }, [dispatch]);

  return (
    <div className="grid grid-cols-3 px-6 lg:px-12 xl:px-48 pt-16 gap-6 sm:gap-10">
      <div className="col-span-3 flex justify-between	w-full">
        <div className="col-span-3">
          <button onClick={() => navigate("/")} className="font-bold">
            <ChevronLeftIcon className="w-6 h-6 inline" />
            Go back to all newsletters
          </button>
        </div>
        {(newsletters && newsletters.length > 0) && (
          <div className="col-span-3">
            <button className="font-bold rounded-md  px-3 py-2 bg-indigo-600 font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" onClick={getMyNewsletter}>
              Refresh my newsletters
            </button>
          </div>
        )}
      </div>

      <div className="col-span-3 w-full h-[1px] bg-gray-200"></div>
      <div className="col-span-3 lg:col-span-2">
        <div className="flex flex-col sm:flex-row">
          <div className="text-xl font-bold my-2 flex-1">My Newsletters</div>
        </div>
        {newsletters && newsletters.map((newsletter) => (
          <div key={newsletter.id}>
            <NewsletterCard newsletterId={newsletter.id} />
          </div>
        ))}
        {(!newsletters || newsletters.length === 0) && (
          <>
            <div className="w-full text-center mt-16">
              There is no newsletter.
            </div>
            <div className="flex justify-center mt-4">
              <button className="font-bold rounded-md  px-3 py-2 bg-indigo-600 font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" onClick={getMyNewsletter}>
                Refresh my newsletters
              </button>
            </div>
          </>
        )}
        <div className="mt-10 mx-auto text-center w-full h-32">
          {isLoadingNewsletters && <LoadingSpinner />}
        </div>
      </div>

      <div className="hidden lg:block md:col-span-1">
        <div className="text-lg font-bold ml-8">Sponsored Newsletters</div>
        {sponsoredNewslettersIsLoading && (
          <div className="w-full text-center mt-4">
            <LoadingSpinner />
          </div>
        )}
        {sponsoredNewsletters &&
          sponsoredNewsletters.map((newsletter) => (
            <div key={newsletter.id}>
              <SponsorNewsletterCard newsletter={newsletter} />
            </div>
          ))}
        <div className="mt-4 italic text-sm text-gray-600 ml-8">
          Want to sponsor your newsletter? Contact us{" "}
          <a
            href="mailto:newsletter.surf@gmail.com"
            className="text-indigo-600"
          >
            here
          </a>
          !
        </div>
        <div className="mt-6 ml-8">
          <a
            href="https://www.producthunt.com/posts/newsletter-surf?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-newsletter&#0045;surf"
            target="_blank"
          >
            <img
              src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=417279&theme=light"
              alt="newsletter&#0046;surf - Discover&#0032;your&#0032;next&#0032;favorite&#0032;newsletter | Product Hunt"
              style={{ width: "250px", height: "54px" }}
              width="250"
              height="54"
            />
          </a>
        </div>
      </div>
      <div className="col-span-3 mt-4 ml-8">
        <LegalLinks />
      </div>
    </div>
  );
}
