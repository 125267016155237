import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { getBearerToken } from "./client";
import { useDispatch } from "react-redux";
import { getMyNewsletters } from "./store/newslettersSlice";
import axios from "axios";
import LoadingSpinner from "./components/LoadingSpinner";

export default function GoogleCallback() {
  const location = useLocation();
  const dispatch = useDispatch();
  
  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const authorizationCode = urlParams.get("code");

    if (authorizationCode) {
      axios.post(
        `${process.env.REACT_APP_BACKEND_HOST}/newsletter/auth/google/callback`,
        { code: authorizationCode },
        {
          headers: {
            'Authorization': getBearerToken(),
            'Content-Type': 'application/json',
          },
        }
      )
      .then((res) => {
        dispatch(getMyNewsletters());
        window.opener.postMessage({ status: 'success' }, '*');
        window.close();
      })
      .catch((error) => {
        console.error("Error exchanging code:", error);
        window.close();
      });
    } else {
      console.error("Authorization code not found in the URL.");
      window.close();
    }
  }, [location]);

  return (
    <div className="flex justify-center items-center customPopup">
      <div className="w-full text-center mt-4 pt-5">
        <LoadingSpinner />
      </div>
    </div>
  );
}
