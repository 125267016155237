import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  current
} from "@reduxjs/toolkit";
import client, { signOut } from "../client";

const newsletterAdapter = createEntityAdapter({
  selectId: (newsletter) => newsletter.id,
});

const initialState = {
  newsletters: newsletterAdapter.getInitialState(),
  isCreatingNewsletter: false,
  creationError: null,
  loadingNewsletters: false,
  newsletterPage: 0,
  myNewsletters: [],
  // Active newsletter
  loadingCurrentNewsletter: false,
  currentNewsletter: null,
  currentReviewIsCreating: false,
  currentReview: null,
  allReviews: null,
  reviewsLoading: false,
  reviewsPage: 0,
  currentReviewSummary: null,

  // Newsletter search
  newsletterSearchResults: null,
  newsletterSearchLoading: false,

  // Sponsored newsletters
  sponsoredNewsletters: null,
  sponsoredNewslettersLoading: false,
};

export const createNewsletter = createAsyncThunk(
  "newsletters/createNewsletter",
  async (newsletterParams) => {
    return await client.createNewsletter(newsletterParams);
  }
);

export const getNewsletters = createAsyncThunk(
  "newsletters/getNewsletters",
  async (filters, { getState }) => {
    const state = getState().newsletters;
    const resp = await client.getNewsletters({
      ...filters,
      page: state.newsletterPage,
      pageSize: 25,
    });
    return resp.data;
  }
);

export const getNewsletter = createAsyncThunk(
  "newsletters/getNewsletter",
  async (slug) => {
    const resp = await client.getNewsletter(slug);
    return resp.data;
  }
);

export const createReview = createAsyncThunk(
  "newsletters/createReview",
  async ({ newsletterId, reviewParams }) => {
    const resp = await client.createReview(newsletterId, reviewParams);
    return resp.data;
  }
);

export const getReviews = createAsyncThunk(
  "newsletters/getReviews",
  async ({ newsletterSlug, rating }, { getState }) => {
    const page = getState().newsletters.reviewsPage;
    const params = { page: page, pageSize: 25 };
    if (rating) {
      params["rating"] = rating;
    }
    const resp = await client.getReviewsForNewsletter(newsletterSlug, params);
    return resp.data;
  }
);

export const deleteReview = createAsyncThunk(
  "newsletters/deleteReview",
  async (reviewId) => {
    await client.deleteReview(reviewId);
    return reviewId;
  }
);

export const searchNewsletters = createAsyncThunk(
  "newsletters/searchNewsletters",
  async (name) => {
    const resp = await client.getNewsletters({ name: name, pageSize: 20 });
    return resp.data;
  }
);

export const upvoteNewsletter = createAsyncThunk(
  "newsletters/upvoteNewsletter",
  async (newsletterId) => {
    await client.upvoteNewsletter(newsletterId);
    return newsletterId;
  }
);

export const downvoteNewsletter = createAsyncThunk(
  "newsletters/downvoteNewsletter",
  async (newsletterId) => {
    await client.downvoteNewsletter(newsletterId);
    return newsletterId;
  }
);

export const getSponsoredNewsletters = createAsyncThunk(
  "newsletters/getSponsoredNewsletters",
  async (newsletterId) => {
    const resp = await client.getSponsoredNewsletters();
    return resp.data;
  }
);

export const getMyNewsletters = createAsyncThunk(
  "newsletters/getMyNewsletters",
  async (_, { getState }) => {
    
    const state = getState().newsletters;
    const resp = await client.getMyNewsletters({
      page: state.newsletterPage,
      pageSize: 25,
    });
    return resp.data;
  }
);

export const newslettersSlice = createSlice({
  name: "newsletters",
  initialState,
  reducers: {
    clearSearchResults: (state) => {
      state.newsletterSearchResults = null;
    },
    incrementPageNumber: (state) => {
      state.newsletterPage += 1;
    },
    resetPageNumber: (state) => {
      state.newsletterPage = 0;
    },
    clearNewsletters: (state) => {
      newsletterAdapter.removeAll(state.newsletters);
    },
    incrementReviewsPageNumber: (state) => {
      state.reviewsPage += 1;
    },
    resetReviewsPageNumber: (state) => {
      state.reviewsPage = 0;
    },
    clearReviews: (state) => {
      state.allReviews = null;
    },
    clearDetailedPageState: (state) => {
      state.currentNewsletter = null;
      state.currentReview = null;
      state.allReviews = null;
      state.reviewsPage = 0;
      state.currentReviewSummary = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createNewsletter.pending, (state) => {
        state.isCreatingNewsletter = true;
        state.creationError = null;
      })
      .addCase(createNewsletter.fulfilled, (state, action) => {
        state.isCreatingNewsletter = false;
        state.creationError = null;
      })
      .addCase(createNewsletter.rejected, (state, action) => {
        state.isCreatingNewsletter = false;
        console.log(action.payload);
        state.creationError =
          "There was an error creating your newsletter. Please try again later.";
      })

      .addCase(getNewsletters.pending, (state) => {
        state.loadingNewsletters = true;
      })
      .addCase(getNewsletters.fulfilled, (state, action) => {
        state.loadingNewsletters = false;
        newsletterAdapter.addMany(state.newsletters, action.payload);
      })
      .addCase(getNewsletters.rejected, (state, action) => {
        signOut();
        state.loadingNewsletters = false;
      })

      .addCase(getNewsletter.pending, (state) => {
        state.loadingCurrentNewsletter = true;
      })
      .addCase(getNewsletter.fulfilled, (state, action) => {
        state.loadingCurrentNewsletter = false;
        state.currentNewsletter = action.payload;
      })
      .addCase(getNewsletter.rejected, (state, action) => {
        state.loadingCurrentNewsletter = false;
      })

      .addCase(createReview.pending, (state) => {
        state.currentReviewIsCreating = true;
      })
      .addCase(createReview.fulfilled, (state, action) => {
        state.currentReviewIsCreating = false;
        state.currentReview = action.payload;
        state.allReviews = [action.payload].concat(state.allReviews || []);
      })
      .addCase(createReview.rejected, (state, action) => {
        state.currentReviewIsCreating = false;
      })

      .addCase(getReviews.pending, (state) => {
        state.reviewsLoading = true;
      })
      .addCase(getReviews.fulfilled, (state, action) => {
        state.reviewsLoading = false;
        state.allReviews = (state.allReviews || []).concat(
          action.payload.allReviews
        );
        state.currentReview = action.payload.myReview;
        state.currentReviewSummary = action.payload.reviewSummary;
      })
      .addCase(getReviews.rejected, (state, action) => {
        state.reviewsLoading = false;
      })

      .addCase(deleteReview.pending, (state) => {})
      .addCase(deleteReview.fulfilled, (state, action) => {
        state.allReviews = (state.allReviews || []).filter(
          (review) => review.id !== action.payload
        );
        state.currentReview = null;
      })
      .addCase(deleteReview.rejected, (state, action) => {})

      .addCase(searchNewsletters.pending, (state) => {
        state.newsletterSearchLoading = true;
      })
      .addCase(searchNewsletters.fulfilled, (state, action) => {
        state.newsletterSearchLoading = false;
        state.newsletterSearchResults = action.payload;
      })
      .addCase(searchNewsletters.rejected, (state, action) => {
        state.newsletterSearchLoading = false;
      })

      .addCase(upvoteNewsletter.pending, (state) => {})
      .addCase(upvoteNewsletter.fulfilled, (state, action) => {
        let currentUpvotes = state.newsletters.entities[action.payload].upvotes;
        newsletterAdapter.updateOne(state.newsletters, {
          id: action.payload,
          changes: {
            upvotes: currentUpvotes + 1,
            userHasUpvoted: true,
          },
        });
      })
      .addCase(upvoteNewsletter.rejected, (state, action) => {})

      .addCase(downvoteNewsletter.pending, (state) => {})
      .addCase(downvoteNewsletter.fulfilled, (state, action) => {
        let currentUpvotes = state.newsletters.entities[action.payload].upvotes;
        newsletterAdapter.updateOne(state.newsletters, {
          id: action.payload,
          changes: {
            upvotes: currentUpvotes - 1,
            userHasUpvoted: false,
          },
        });
      })
      .addCase(downvoteNewsletter.rejected, (state, action) => {})

      .addCase(getSponsoredNewsletters.pending, (state) => {
        state.sponsoredNewslettersLoading = true;
      })
      .addCase(getSponsoredNewsletters.fulfilled, (state, action) => {
        state.sponsoredNewslettersLoading = false;
        state.sponsoredNewsletters = action.payload;
      })
      .addCase(getSponsoredNewsletters.rejected, (state, action) => {
        state.sponsoredNewslettersLoading = false;
      })

      .addCase(getMyNewsletters.pending, (state) => {
        state.loading = true;
      })
      .addCase(getMyNewsletters.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getMyNewsletters.fulfilled, (state, action) => {
        state.myNewsletters = action.payload ? [...action.payload] : [];
      });
  },
});

export const selectNewsletterCreationError = (state) =>
  state.newsletters.creationError;
export const selectIsCreatingNewsletter = (state) =>
  state.newsletters.isCreatingNewsletter;
export const selectLoadingCurrentNewsletter = (state) =>
  state.newsletters.loadingCurrentNewsletter;
export const selectCurrentNewsletter = (state) =>
  state.newsletters.currentNewsletter;
export const selectCurrentReview = (state) => state.newsletters.currentReview;
export const selectCurrentReviewIsCreating = (state) =>
  state.newsletters.currentReviewIsCreating;
export const selectAllReviews = (state) => state.newsletters.allReviews;
export const selectCurrentReviewSummary = (state) =>
  state.newsletters.currentReviewSummary;
export const selectNewsletterSearchResults = (state) =>
  state.newsletters.newsletterSearchResults;
export const selectNewsletterSearchLoading = (state) =>
  state.newsletters.newsletterSearchLoading;
export const selectSponsoredNewsletters = (state) =>
  state.newsletters.sponsoredNewsletters;
export const selectSponsoredNewslettersIsLoading = (state) =>
  state.newsletters.sponsoredNewslettersLoading;
export const selectIsLoadingNewsletters = (state) =>
  state.newsletters.loadingNewsletters;
export const selectIsReviewsLoading = (state) =>
  state.newsletters.reviewsLoading;
export const selectPageReviewNumber = (state) => state.newsletters.reviewsPage;

export const selectMyNewsletters = (state) => state.newsletters.myNewsletters;

export const {
  selectAll: selectAllNewsletters,
  selectById: selectNewsletterById,
} = newsletterAdapter.getSelectors((state) => state.newsletters.newsletters);

export const {
  clearDetailedPageState,
  clearSearchResults,
  incrementPageNumber,
  resetPageNumber,
  clearNewsletters,
  incrementReviewsPageNumber,
  clearReviews,
  resetReviewsPageNumber,
} = newslettersSlice.actions;

export default newslettersSlice.reducer;
