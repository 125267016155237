import "./App.css";
import Navbar from "./Navbar";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { Provider, useDispatch, useSelector } from "react-redux";
import store from "./store";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  Route,
  Router,
  RouterProvider,
} from "react-router-dom";
import CreateNewsletterPage from "./features/CreateNewsletterPage";
import MyNewslettersPage from "./features/MyNewslettersPage";
import { useEffect, useState } from "react";
import {
  getIndustries,
  getNewsletterTypes,
  selectSigninPromptOpen,
  setSigninPromptOpen,
} from "./store/globalSlice";
import { getNewsletters } from "./store/newslettersSlice";
import NewsletterHomePage from "./features/NewsletterHomePage";
import NewsletterPage from "./features/NewsletterPage";
import SigninPromptModal from "./components/SigninPromptModal";
import { selectProfile } from "./store/userSlice";
import GoogleCallback from "./GoogleCallback";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<HomeWrapper />}>
      <Route path="" element={<NewsletterHomePage />} />
      <Route path="/create" element={<CreateNewsletterPage />} />
      <Route path="/newsletter/:newsletterSlug" element={<NewsletterPage />} />
      <Route path="/mynewsletters" element={<MyNewslettersPage />} />
      <Route path="/newsletter/auth/google/callback" element={<GoogleCallback />} />
    </Route>
  )
);

function HomeWrapper() {
  const dispatch = useDispatch();

  const signinPromptOpen = useSelector(selectSigninPromptOpen);

  useEffect(() => {
    dispatch(getIndustries());
    dispatch(getNewsletterTypes());
  }, [dispatch]);

  return (
    <div>
      <Navbar />
      <SigninPromptModal
        open={signinPromptOpen}
        setOpen={(o) => dispatch(setSigninPromptOpen(o))}
      />
      <div className="py-8">
        <Outlet />
      </div>
    </div>
  );
}

function App() {
  return (
    <Provider store={store}>
      <GoogleOAuthProvider clientId="556601770330-bpmrc9plqo0hjr74ujtvcrm5gnojndds.apps.googleusercontent.com">
        <div>
          <RouterProvider router={router} />
        </div>
      </GoogleOAuthProvider>
    </Provider>
  );
}

export default App;
