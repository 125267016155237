import {useDispatch, useSelector} from "react-redux";
import {downvoteNewsletter, selectNewsletterById, upvoteNewsletter} from "../store/newslettersSlice";
import {getImageSrc} from "../utilities";
import {IndustryBadge} from "./IndustryBadge";
import Rating from "./Rating";
import {ChevronDoubleUpIcon,} from "@heroicons/react/20/solid";
import {useNavigate} from "react-router-dom";
import {useCallback, useState} from "react";
import SigninPromptModal from "./SigninPromptModal";
import {selectProfile} from "../store/userSlice";
import {setSigninPromptOpen} from "../store/globalSlice";


export default function NewsletterCard({newsletterId}) {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const newsletter = useSelector(state => selectNewsletterById(state, newsletterId));
  const isLoggedIn = useSelector(selectProfile) != null;


  const upvote = useCallback((e) => {
    e.stopPropagation()
    if (!isLoggedIn) {
      dispatch(setSigninPromptOpen(true));
      return;
    }
    dispatch(upvoteNewsletter(newsletterId));
  }, [newsletter, dispatch])

  const downvote = useCallback((e) => {
    e.stopPropagation()
    if (!isLoggedIn) {
      dispatch(setSigninPromptOpen(true));
      return;
    }
    dispatch(downvoteNewsletter(newsletterId));
  }, [newsletter, dispatch])

  if (newsletter == null || newsletter == undefined) {
    return null;
  }

  return (
    <div
      key={newsletterId}
      onClick={() => navigate('/newsletter/' + newsletter?.slug)}
      className="flex flex-row p-4 transition-all duration-300 ease-in-out hover:bg-gray-100 rounded-lg mt-2 cursor-pointer"
    >
      <div>
        <img src={getImageSrc(newsletter?.logoReference)} alt="newsletter image" className="w-12 h-12 sm:w-20 sm:h-20 rounded-md" />
      </div>
      <div className="pl-4 flex-1">
        <div className="font-bold text-lg">{newsletter?.name}</div>
        <Rating rating={newsletter?.averageScore} />
        <div className="flex flex-row mt-2">
          <IndustryBadge industry={newsletter?.industry} />
          {newsletter?.newsletterType && newsletter.newsletterType.name !== "Other" &&
            (
              <img src={getImageSrc(newsletter?.newsletterType?.logoReference)} alt="newsletter type image" className="w-6 h-6 rounded-md ml-2" />
            )
          }
        </div>
      </div>
      <button
        onClick={(e) => newsletter?.userHasUpvoted ? downvote(e) : upvote(e)}
        className={`${newsletter.userHasUpvoted ? "bg-indigo-100" : ""} px-2 hover:border-solid border-[1.5px] border-white hover:border-indigo-300 duration-200 ease-in-out rounded-lg text-center`}>
        <ChevronDoubleUpIcon className="w-8 h-8 mx-auto my-2"/>
        <div className="text-lg font-bold">{newsletter.upvotes}</div>
      </button>
    </div>
  )
}